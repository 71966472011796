import type { LexicalEditor } from 'lexical';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { t } from '@/utility/localization';

import NPLButton from '@/components/npl/NPLButton';
import NPLInput from '@/components/npl/NPLInput/NPLInput';

import { checkIfUrlIsValid } from '@/pages/portal/utils/common';

import { INSERT_YOUTUBE_COMMAND } from '.';
import Modal from '../../../Modal';

type Props = {
  activeEditor: LexicalEditor;
  onClose: () => void;
  open: boolean;
};

const YoutubeEmbedModal: FC<Props> = ({ activeEditor, open, onClose }) => {
  const [link, setLink] = useState('');
  const [linkErrorMessage, setLinkErrorMessage] = useState('');

  useEffect(() => {
    if (link !== '' && linkErrorMessage !== '') {
      setLinkErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  const onSubmit = () => {
    if (!link) {
      setLinkErrorMessage(t('link-is-required'));
      return;
    }

    if (link && !checkIfUrlIsValid(link)) {
      setLinkErrorMessage(t('invalid-url'));
      return;
    }

    if (link && link.includes('youtube.com/shorts/')) {
      setLinkErrorMessage(t('youtube-shorts-not-supported'));
      return;
    }

    let formattedLink = link;

    if (link.indexOf('https') === -1 && link.indexOf('http') === -1) {
      formattedLink = `https://${link}`;
    }

    activeEditor.dispatchCommand(INSERT_YOUTUBE_COMMAND, formattedLink);
    setLink('');
    onClose();
  };

  return (
    <Modal
      size="md"
      open={open}
      onClose={onClose}
      customCloseIconClass={undefined}
      containerBackgroundTransparent={undefined}
      eyebrow={undefined}
      customEyeBrowClass={undefined}>
      <div className="flex flex-col items-stretch font-poppins">
        <h4 className="text-heading-sm font-medium text-dark-33">
          {t('embed-youtube-link')}
        </h4>
        <div className="mt-16">
          <NPLInput
            placeholder="https://www.youtube.com/watch?v=yIkguQzdVfQ&ab"
            testId="button-link"
            value={link}
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              setLink(e.target.value)
            }
            error={linkErrorMessage}
          />
        </div>
        <div className="mt-16 flex flex-row justify-end gap-16">
          <NPLButton
            size="lg"
            onClick={onClose}
            hierarchy="outline"
            buttonText={t('cancel')}
          />
          <NPLButton
            size="lg"
            onClick={onSubmit}
            hierarchy="accent_primary"
            buttonText={t('embed-video')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default YoutubeEmbedModal;
