import { useMaskito } from '@maskito/react';
import classNames from 'classnames';
import React, {
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react';

import NPLInputLeadTailSection from './components/NPLInputLeadTailSection';
import NPLInputTopSection from './components/NPLInputTopSection';
import type { NPLInputProps } from './types';
import {
  getInputDisplayType,
  getInputPaddingClassName,
  getInputTextClassName
} from './utils';

type NPLInputPropsWithoutRef = Omit<NPLInputProps, 'forwardRef'>;

const NPLInputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  NPLInputProps
> = (
  {
    size = 'md',
    type = 'outline',

    label,
    labelDescription,
    isRequired,
    tooltipText,
    buttonText,
    buttonOnClick,
    rounded,

    leadText,
    leadIconName,
    leadSelection,

    placeholder,
    disabled,
    value = '',
    inputType = 'text',

    tailSelection,
    tailText,
    tailIconName,
    tailOnClick,

    supportingText,
    error,
    maxTextCount,
    testId,

    maskPattern,

    hasChinContent,
    onInput,
    onClick,
    onBlur,
    name,

    isSlugInput,
    tailSupportingText,
    isMoneyValue = false
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  const maskPatternRef = useMaskito({ options: maskPattern });

  const inputRef = (ref as Ref<HTMLInputElement>) || maskPatternRef;

  const inputFocusClassNames = `border-1 border-npl-yellow-light-solid-12 ${
    isFocused && hasChinContent ? 'rounded-b-0' : ''
  }`;

  const hasLeadProps = leadText || leadIconName || leadSelection;
  const hasTailProps = tailText || tailIconName || tailSelection;

  const showNPLInputTopSection =
    label || labelDescription || tooltipText || buttonText;
  return (
    <div className="c-NPLInput">
      {showNPLInputTopSection && (
        <div className="mb-6">
          <NPLInputTopSection
            label={label}
            labelDescription={labelDescription}
            isRequired={isRequired}
            tooltipText={tooltipText}
            buttonText={buttonText}
            buttonOnClick={buttonOnClick}
          />
        </div>
      )}
      <div
        className={classNames([
          'c-NPLInput flex min-h-[40px] items-center bg-white-default text-body-sm transition-all',
          {
            'rounded-t-8': hasChinContent,
            'rounded-8': !hasChinContent,
            'bg-npl-neutral-light-solid-3': disabled,
            'overflow-hidden rounded-full': rounded
          },
          isFocused && inputFocusClassNames,
          getInputDisplayType(type)
        ])}>
        {hasLeadProps && (
          <NPLInputLeadTailSection
            iconOnClick=""
            text={leadText}
            iconName={leadIconName}
            selectionProps={leadSelection}
            isTail={false}
            isSlugInput={isSlugInput}
          />
        )}

        <input
          ref={inputRef}
          onInput={onInput}
          onClick={onClick}
          name={name}
          id={name}
          data-test-id={
            testId ||
            `npl-input-${label?.replace(/\s/g, '-').toLowerCase() || ''}`
          }
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
            setIsFocused(false);
          }}
          type={inputType}
          disabled={disabled}
          value={value}
          placeholder={disabled ? '' : placeholder}
          className={classNames([
            `w-full h-full flex-shrink flex-grow rounded-8 text-neutral-10 outline-none transition-all bg-transparent`,
            {
              'rounded-l-0': hasLeadProps,
              'rounded-r-0': hasTailProps,
              'ring-1 ring-npl-error-light-11': error,
              'text-npl-text-icon-on-light-surface-tertiary': disabled,
              'text-right': isMoneyValue
            },
            getInputPaddingClassName(size),
            getInputTextClassName(size)
          ])}
        />
        {hasTailProps && (
          <NPLInputLeadTailSection
            text={tailText}
            iconName={tailIconName}
            selectionProps={tailSelection}
            iconOnClick={tailOnClick}
            isTail
          />
        )}
      </div>
      {(tailSupportingText || supportingText || error || maxTextCount) && (
        <div className="flex items-center mt-6 text-body-sm text-npl-text-icon-on-light-surface-secondary">
          {(supportingText || error) && (
            <div
              className={`${
                error
                  ? 'text-npl-error-light-9'
                  : 'text-npl-text-icon-on-light-surface-secondary'
              }`}>
              {error ? error : supportingText}
            </div>
          )}

          {tailSupportingText && (
            <div className="flex justify-end flex-grow font-medium text-label-sm text-npl-text-tertiary-on-light">
              {tailSupportingText}
            </div>
          )}
          {maxTextCount && typeof value === 'string' ? (
            <div className="flex justify-end flex-grow">
              {value?.length}/{maxTextCount}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const NPLInput = forwardRef<HTMLInputElement, NPLInputPropsWithoutRef>(
  NPLInputBase
);

export default NPLInput;
