import React from 'react';

import NplToolTip from '@/components/common/NplToolTip';

import Icon from '../../Icon';
import NPLLinkButton, { LINK_BTN_HIERARCHY } from '../../NPLLinkButton';

function NPLInputTopSection({
  label,
  labelDescription,
  isRequired,
  tooltipText,
  buttonText,
  buttonOnClick
}) {
  return (
    <div className="flex justify-between">
      <div className="flex gap-4">
        {label && (
          <p className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
            {label}
          </p>
        )}
        {labelDescription && (
          <p className="text-label-sm text-npl-text-icon-on-light-surface-secondary">
            {labelDescription}
          </p>
        )}
        {isRequired && (
          <p className="text-label-md font-medium text-npl-error-light-9">
            *
          </p>
        )}
        {tooltipText && (
          <NplToolTip text={tooltipText} title={label}>
            <Icon
              name="info-circle"
              width={16}
              height={16}
              fill="#1B1B1859"
            />
          </NplToolTip>
        )}
      </div>
      {buttonText && (
        <NPLLinkButton
          hierarchy={LINK_BTN_HIERARCHY.ACCENT}
          onClick={buttonOnClick}
          buttonText={buttonText}
          size="xs"
        />
      )}
    </div>
  );
}

export default NPLInputTopSection;
