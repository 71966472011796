import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand
} from 'lexical';
import { useEffect } from 'react';

import {
  $createButtonNode,
  ButtonNode,
  ButtonNodeType
} from '../../nodes/ButtonNode';

export const INSERT_BUTTON_COMMAND = createCommand();
export default function ButtonPlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!editor.hasNodes([ButtonNode])) {
      throw new Error('ButtonPlugin: ButtonNode not registered on editor');
    }
    return mergeRegister(
      editor.registerCommand(
        INSERT_BUTTON_COMMAND,
        (payload: unknown) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            if ($isRootNode(selection.anchor.getNode())) {
              selection.insertParagraph();
            }
            const buttonNode = $createButtonNode(
              payload as ButtonNodeType
            );
            selection.insertNodes([buttonNode, $createParagraphNode()]);
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
}
