import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode
} from '@lexical/react/LexicalDecoratorBlockNode';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  DOMConversionMap,
  EditorConfig,
  ElementFormatType,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  LexicalNode,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { MouseEventHandler, useCallback, useEffect, useRef } from 'react';

import Button from '../../Button';

const convertButtonElement = (domNode) => {
  const id = domNode.getAttribute('data-lexical-button-id');
  if (id) {
    const node = $createButtonNode(id);
    return { node };
  }
  return null;
};

export type ButtonNodeType = {
  key: ElementFormatType;
  label: string;
  link: string;
  format?: ElementFormatType;
};

const ButtonComponent = ({ label, link, nodeKey, className, format }) => {
  const buttonRef = useRef<null | HTMLDivElement>(null);
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editor] = useLexicalComposerContext();
  const activeEditorRef = useRef<LexicalEditor | null>(null);

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if (node && $isButtonNode(node)) {
          node.remove();
        }
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  const onClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    if (event.target === buttonRef.current) {
      if (event.shiftKey) {
        setSelected(!isSelected);
      } else {
        clearSelection();
        setSelected(true);
      }
    }
  };

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (buttonRef.current === event.target) {
        $setSelection(null);
        editor.update(() => {
          setSelected(true);
          const parentRootElement = editor.getRootElement();
          if (parentRootElement !== null) {
            parentRootElement.focus();
          }
        });
        return true;
      }
      return false;
    },
    [editor, setSelected]
  );
  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      const latestSelection = $getSelection();
      const buttonElem = buttonRef.current;

      if (
        isSelected &&
        $isNodeSelection(latestSelection) &&
        latestSelection.getNodes().length === 1
      ) {
        const imageNode = $getNodeByKey(nodeKey);
        const paragraphNode = $createParagraphNode();
        imageNode?.insertAfter(paragraphNode);
        paragraphNode.select();
        if (buttonElem !== null && buttonElem !== document.activeElement) {
          event.preventDefault();
          buttonElem.focus();
          return true;
        }
      }
      return false;
    },
    [isSelected]
  );

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        onEscape,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        onEnter,
        COMMAND_PRIORITY_LOW
      )
    );

    return () => {
      unregister();
    };
  }, [
    clearSelection,
    editor,
    isSelected,
    nodeKey,
    onDelete,
    onClick,
    setSelected
  ]);

  return (
    <BlockWithAlignableContents
      className={className}
      format={format}
      nodeKey={nodeKey}>
      <div
        className={`flex w-full cursor-pointer flex-row items-center justify-center py-12 ${
          Boolean(isSelected) && 'focused'
        }`}
        ref={buttonRef}
        onClick={onClick}>
        <Button
          displayType="primary"
          rounded
          openLinkInNewTab
          fluid={undefined}
          withShadow={undefined}
          withBorder={undefined}
          narrow={undefined}
          newVersion={undefined}
          customClassNames={undefined}
          disabled={undefined}
          animated={undefined}
          small={undefined}
          link={link}
          slim={undefined}>
          <span className="font-helvetica text-button-md font-bold">
            {label}
          </span>
        </Button>
      </div>
    </BlockWithAlignableContents>
  );
};

export class ButtonNode extends DecoratorBlockNode {
  static getType() {
    return 'button';
  }

  __link: string;
  __label: string;
  __id: string;

  static clone(node: ButtonNode): ButtonNode {
    const clone = new ButtonNode(
      node.__link,
      node.__label,
      node.__key as ElementFormatType,
      node.__format
    );

    // Explicitly set the key after creation
    // @Trent- recheck why need to do this
    // Currently its because key shouldn't be a format type
    clone.__key = node.__key;

    return clone;
  }

  constructor(
    link: string,
    label: string,
    key: ElementFormatType,
    format?: ElementFormatType
  ) {
    super(key);
    this.__link = link;
    this.__label = label;
    if (format !== undefined) {
      this.__format = format;
    }
  }

  static importJSON(serializedNode: unknown) {
    const node = $createButtonNode(serializedNode as ButtonNodeType);
    return node;
  }

  exportJSON(): SerializedDecoratorBlockNode & {
    label: string;
    link: string;
  } {
    return {
      ...super.exportJSON(),
      link: this.__link,
      label: this.__label,
      type: 'button',
      version: 1
    };
  }

  // View
  static importDOM(): DOMConversionMap<HTMLElement> {
    return {
      div: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-button-id')) {
          return null;
        }
        return {
          conversion: convertButtonElement,
          priority: 2
        };
      }
    };
  }

  exportDOM() {
    const element = document.createElement('div');
    element.setAttribute('data-lexical-button-id', this.__id);
    return { element };
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    return dom;
  }

  updateDOM(): false {
    return false;
  }

  getLink() {
    return this.__link;
  }
  getLabel() {
    return this.__label;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || ''
    };
    return (
      <ButtonComponent
        link={this.getLink()}
        label={this.getLabel()}
        nodeKey={this.__key}
        className={className}
        format={this.__format}
      />
    );
  }

  isTopLevel() {
    return true;
  }
}

export function $createButtonNode(params: ButtonNodeType) {
  const { link, label, key, format } = params;
  return new ButtonNode(link, label, format, key);
}

export function $isButtonNode(node: LexicalNode | null | undefined) {
  return node instanceof ButtonNode;
}
