import { NPLInputDisplayTypes } from './types';

export const getInputTextClassName = (size: string) => {
  switch (size) {
    case 'sm':
      return 'text-body-sm';
    case 'md':
      return 'text-body-md';
  }
};

export const getInputPaddingClassName = (size: string) => {
  switch (size) {
    case 'sm':
      return 'px-16 py-8';
    case 'md':
      return 'px-16 py-12';
  }
};

export const getInputDisplayType = (type: NPLInputDisplayTypes) => {
  switch (type) {
    case 'filled':
      return 'border border-white-default bg-npl-neutral-light-solid-2 ';
    case 'outline':
      return 'border border-npl-neutral-light-solid-7 bg-transparent shadow-npl-styles-button-shadow ';
  }
};
