import style from './Button.module.scss';

import classnames from 'classnames';
import Link from 'next/link';
import { PropTypes } from 'prop-types';

const buttonDisplayTypes = {
  plain: 'plain',
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  outline: 'outline',
  grey: 'grey',
  flat: 'flat',
  base: 'base',
  'form-primary': 'form-primary',
  'form-secondary': 'form-secondary',
  'primary-inverted': 'primary-inverted',
  link: 'link',
  'bg-transparent-wo-border': 'bg-transparent-wo-border',
  'bg-transparent-w-border': 'bg-transparent-w-border',
  'bg-transparent-w-border-light': 'bg-transparent-w-border-light'
};

const buttonDisplayTypesArr = [...Object.keys(buttonDisplayTypes), ''];

const Button = ({
  displayType,
  fluid,
  withShadow,
  withBorder,
  narrow,
  slim,
  children,
  newVersion,
  customClassNames,
  disabled,
  rounded,
  animated,
  small,
  link,
  openLinkInNewTab,
  ...btnProps
}) => {
  const className = 'c-Button';
  const classNames = classnames([
    className,
    style[className],
    displayType ? style[`${className}--${displayType}`] : '',
    displayType ? `${className}--${displayType}` : '',
    fluid ? style[`${className}--fluid`] : '',
    withShadow ? style[`${className}--shadow`] : '',
    narrow ? style[`${className}--narrow`] : '',
    slim ? style[`${className}--slim`] : '',
    newVersion ? style[`${className}--new-version`] : '',
    withBorder ? style[`${className}--with-border`] : '',
    disabled ? style[`${className}--disabled`] : '',
    rounded ? style[`${className}--rounded`] : '',
    animated ? style[`${className}--animated--${displayType}`] : '',
    small ? style[`${className}--small`] : '',
    customClassNames ? customClassNames : ''
  ]);

  if (link) {
    return (
      <Link
        href={link}
        target={openLinkInNewTab ? '_blank' : '_self'}
        className={`block ${classNames}`}
        disabled={disabled}
        {...btnProps}>
        {children}
      </Link>
    );
  }

  return (
    <button className={classNames} disabled={disabled} {...btnProps}>
      {children}
    </button>
  );
};

Button.propTypes = {
  fluid: PropTypes.bool,
  displayType: PropTypes.oneOf(buttonDisplayTypesArr),
  withShadow: PropTypes.bool,
  narrow: PropTypes.bool,
  slim: PropTypes.bool,
  newVersion: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
