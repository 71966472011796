import type { Spread } from 'lexical';
import {
  $applyNodeReplacement,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  TextNode
} from 'lexical';

export type SerializedVariableNode = Spread<
  {
    variable: string;
  },
  SerializedTextNode
>;

function convertVariableElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createVariableNode(textContent);
    return {
      node
    };
  }

  return null;
}

export class VariableNode extends TextNode {
  __variable: string;

  static getType(): string {
    return 'variable';
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__variable, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(serializedNode.variable);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(variable: string, text?: string, key?: NodeKey) {
    super(text ?? variable, key);
    this.__variable = variable;
    this.__text = text;
  }

  exportJSON(): SerializedVariableNode {
    return {
      ...super.exportJSON(),
      variable: this.__variable,
      type: VariableNode.getType(),
      version: 1
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);

    dom.className =
      'px-4 py-2 text-npl-yellow-light-solid-11 bg-npl-yellow-light-solid-3 rounded-4';
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-variable', 'true');
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-variable')) {
          return null;
        }
        return {
          conversion: convertVariableElement,
          priority: 1
        };
      }
    };
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createVariableNode(
  variable: string,
  text?: string
): VariableNode {
  const node = new VariableNode(variable, text);
  node.setMode('token').toggleDirectionless();
  return $applyNodeReplacement(node);
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode;
}
