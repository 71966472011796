import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import cloneDeep from 'lodash/cloneDeep';

import { VariableOption } from '../Editor';

export function sanitizeLinkNodes(nodes: unknown): unknown {
  const sanitizedNode: { [key: string]: unknown } = cloneDeep(nodes) as {
    [key: string]: unknown;
  };
  if (sanitizedNode.children && Array.isArray(sanitizedNode.children)) {
    sanitizedNode.children = sanitizedNode.children.map((node) =>
      sanitizeLinkNodes(node)
    );
  }
  if (sanitizedNode.root) {
    sanitizedNode.root = sanitizeLinkNodes(sanitizedNode.root);
  }
  if (
    typeof sanitizedNode === 'object' &&
    sanitizedNode !== null &&
    !Object.prototype.hasOwnProperty.call(sanitizedNode, 'children') &&
    sanitizedNode.type === 'link'
  ) {
    sanitizedNode.url = sanitizedNode.link;
    sanitizedNode.rel = 'noreferrer';
    sanitizedNode.children = [
      {
        mode: 'normal',
        text: sanitizedNode.label,
        type: 'text',
        version: 1
      }
    ];
  }
  return sanitizedNode;
}

export function sanitizeReadOnlyVariableNodes(
  nodes: unknown,
  variables: Array<VariableOption>,
  isEditable: boolean
): unknown {
  const sanitizedNode: { [key: string]: unknown } = cloneDeep(nodes) as {
    [key: string]: unknown;
  };
  if (sanitizedNode.children && Array.isArray(sanitizedNode.children)) {
    sanitizedNode.children = sanitizedNode.children.map((node) =>
      sanitizeReadOnlyVariableNodes(node, variables, isEditable)
    );
  }
  if (sanitizedNode.root) {
    sanitizedNode.root = sanitizeReadOnlyVariableNodes(
      sanitizedNode.root,
      variables,
      isEditable
    );
  }
  if (
    typeof sanitizedNode === 'object' &&
    sanitizedNode !== null &&
    !Object.prototype.hasOwnProperty.call(sanitizedNode, 'children') &&
    sanitizedNode.type === 'variable'
  ) {
    const foundVariable = variables.find(
      ({ variable }) => variable === sanitizedNode.variable
    );
    //replace the variable text with the actual values
    if (foundVariable) {
      sanitizedNode.text = foundVariable.getPreviewValue
        ? foundVariable.getPreviewValue()
        : foundVariable.text;
      sanitizedNode.type = 'text';
    }
  }

  if (
    typeof sanitizedNode === 'object' &&
    sanitizedNode !== null &&
    !Object.prototype.hasOwnProperty.call(sanitizedNode, 'children') &&
    sanitizedNode.type === 'text'
  ) {
    variables.forEach(({ variable, text, getPreviewValue }) => {
      if (typeof sanitizedNode.text !== 'string') {
        return;
      }

      const variableRegex = new RegExp(`${variable}`, 'gi');
      sanitizedNode.text = sanitizedNode.text.replace(
        variableRegex,
        getPreviewValue ? getPreviewValue() : text
      );
    });
  }

  return sanitizedNode;
}

export const isEditorEmpty = (content) => {
  if (!content) {
    return true;
  }

  if (!content.root) {
    return true;
  }

  if (!Object.keys(content).length) {
    return true;
  }

  if (content.root && content.root.children?.length === 0) {
    return true;
  }
  // check if lexical content is empty
  // can't just check if content object is empty because that will only check if content has been initialised before,
  // if the content is deleted, the object will exist, but the children array will be empty so we have to check that children array
  return (
    content.root.children.length === 1 &&
    (!content.root.children[0].children ||
      content.root.children[0].children.length === 0)
  );
};

export const getFirstImageUrlFromEditor = (content) => {
  // Flatten the structure and find the first image node
  const traverse = (nodes) => {
    for (const node of nodes) {
      if (node.type === 'image' && node.src) {
        return node.src;
      }
      if (node.children) {
        const result = traverse(node.children);
        if (result) return result;
      }
    }
    return null;
  };

  // Start traversal from the root
  return traverse(content.root.children);
};

export const sanitizeContentNodes = ({
  content,
  variables,
  isEditable
}: {
  content: SerializedEditorState<SerializedLexicalNode>;
  isEditable?: boolean;
  variables?: Array<VariableOption>;
}) => {
  let sanitizedContent = sanitizeLinkNodes(
    content
  ) as SerializedEditorState<SerializedLexicalNode>;

  if (!isEditable) {
    sanitizedContent = sanitizeReadOnlyVariableNodes(
      sanitizedContent,
      variables,
      isEditable
    ) as SerializedEditorState<SerializedLexicalNode>;
  }

  return sanitizedContent;
};
