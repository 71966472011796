import styles from './index.module.scss';
import themeStyles from './themes/PlaygroundEditorTheme.module.scss';

import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import classNames from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_NORMAL,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  SerializedEditorState,
  SerializedLexicalNode
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';

import { isSafari } from '@/utility/browserUtils';
import { t } from '@/utility/localization';

import { VariableTypes } from '@/pages/portal/utils/common';

import AnimatedShowHideDiv from '../AnimatedShowHideDiv/AnimatedShowHideDiv';
import { useSettings } from './context/SettingsContext';
import { useSharedHistoryContext } from './context/SharedHistoryContext';
import NodeList from './nodes/NodeList';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import AutocompletePlugin from './plugins/AutocompletePlugin';
import ButtonPlugin from './plugins/ButtonPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin, { ImageIdentity } from './plugins/ImagesPlugin';
import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import { ParagraphPlaceholderPlugin } from './plugins/ParagraphPlaceholderPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import VariablePlugin from './plugins/VariablePlugin';
import type { VariableOption } from './plugins/VariablePlugin';
import YouTubePlugin from './plugins/YoutubePlugin';
import { CAN_USE_DOM } from './shared/canUseDOM';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import Placeholder from './ui/Placeholder';
import { sanitizeContentNodes } from './utils/common';

export type { VariableOption };
export { VariableTypes };

export type EditorProps = {
  editable: boolean;
  existingContent: SerializedEditorState<SerializedLexicalNode>;
  imageIdentity: ImageIdentity;
  isAutoFocus: boolean;
  placeholderText: string;
  contentHTML?: string | null;
  customBorderClass?: string;
  customContentClasses?: string;
  customFocusClass?: string;
  hideButtonPlugin?: boolean;
  onChange?: (editorState: unknown, contentHTML: unknown) => void;
  showToolBarOnHover?: boolean;
  supportsYoutubeEmbed?: boolean;
  variables?: Array<VariableOption>;
};

export const enum ModalOpenState {
  Button,
  Youtube
}

function EditorComponent({
  onChange,
  placeholderText,
  customContentClasses,
  imageIdentity,
  variables,
  hideButtonPlugin = false,
  isAutoFocus,
  supportsYoutubeEmbed,
  customBorderClass,
  customFocusClass,
  showToolBarOnHover
}: Omit<EditorProps, 'existingContent' | 'editable'>): JSX.Element {
  const { historyState } = useSharedHistoryContext();
  const [editor] = useLexicalComposerContext();
  const {
    settings: {
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText
    }
  } = useSettings();

  const isEditable = useLexicalEditable();
  const text = placeholderText || t('what-do-you-want-to-share-with');
  const placeholder = isEditable ? (
    <Placeholder>{text}</Placeholder>
  ) : (
    <></>
  );
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isSmallWidthViewport, setIsSmallWidthViewport] =
    useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [modalOpenState, setModalOpenState] =
    useState<ModalOpenState>(null);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) &&
          selection.anchor.getNode() &&
          $isRootNode(selection.anchor.getNode())
        ) {
          selection.insertParagraph();
        }
        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia('(max-width: 768px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };
    updateViewPortWidth();
    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const editableBorderClass =
    'border-1 border-npl-neutral-light-solid-7 rounded-8';

  const isEditorEmpty = useLexicalIsTextContentEmpty(editor);

  return (
    <div
      ref={toolbarRef}
      className={`${themeStyles['editor-theme']} ${
        styles['common-editor-styles']
      } ${!isEditable && themeStyles['read-only']} 
      ${customBorderClass || (isEditable ? editableBorderClass : '')}
      ${isFocused ? customFocusClass : ''} overflow-clip`}>
      <div
        className={`editor-container text-npl-text-icon-on-light-surface-primary ${
          !isRichText && 'plain-text'
        }`}
        onFocus={handleFocus}
        onBlur={handleBlur}>
        {isMaxLength && <MaxLengthPlugin maxLength={30} />}
        <DragDropPaste />
        {isAutoFocus && <AutoFocusPlugin />}
        <ClearEditorPlugin />
        <ComponentPickerPlugin
          setModalOpenState={setModalOpenState}
          supportsYoutubeEmbed={supportsYoutubeEmbed}
        />
        <AutoLinkPlugin />
        {variables && <VariablePlugin variables={variables} />}
        {isEditable ? (
          <>
            <ParagraphPlaceholderPlugin
              placeholder={
                isEditorEmpty ? placeholderText : t('type-for-commands')
              }
            />
            <OnChangePlugin
              onChange={(_editorState, editor) => {
                _editorState.read(() => {
                  const htmlString = $generateHtmlFromNodes(editor, null);
                  const content = JSON.parse(JSON.stringify(_editorState));
                  onChange?.({ content }, htmlString);
                });
              }}
            />
            {showToolBarOnHover ? (
              <AnimatedShowHideDiv show={isFocused}>
                <ToolbarPlugin
                  setIsLinkEditMode={setIsLinkEditMode}
                  supportsYoutubeEmbed={supportsYoutubeEmbed}
                  setModalOpenState={setModalOpenState}
                  modalOpenState={modalOpenState}
                  isButtonAllowed={!hideButtonPlugin}
                  isVariableAllowed={variables?.length > 0}
                />
              </AnimatedShowHideDiv>
            ) : (
              <ToolbarPlugin
                setIsLinkEditMode={setIsLinkEditMode}
                supportsYoutubeEmbed={supportsYoutubeEmbed}
                setModalOpenState={setModalOpenState}
                modalOpenState={modalOpenState}
                isButtonAllowed={!hideButtonPlugin}
                isVariableAllowed={variables?.length > 0}
              />
            )}

            <HistoryPlugin externalHistoryState={historyState} />
            <RichTextPlugin
              contentEditable={
                <div className="editor-scroller border-x-1 border-npl-neutral-light-solid-7">
                  <div className="editor" ref={onRef}>
                    <ContentEditable
                      className={classNames(
                        'min-h-160 h-full border-0 py-16 outline-0 pl-28 pr-12',
                        customContentClasses
                      )}
                    />
                  </div>
                </div>
              }
              placeholder={null}
              ErrorBoundary={LexicalErrorBoundary}
            />
            {hideButtonPlugin ? null : <ButtonPlugin />}
            <ListPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <ImagesPlugin imageIdentity={imageIdentity} />
            <LinkPlugin />
            <LexicalClickableLinkPlugin />
            <HorizontalRulePlugin />
            <TabFocusPlugin />
            <TabIndentationPlugin />
            <LayoutPlugin />
            {floatingAnchorElem && (
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            )}
            {floatingAnchorElem && !isSmallWidthViewport && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                />
              </>
            )}
            <YouTubePlugin />
          </>
        ) : (
          <>
            <PlainTextPlugin
              contentEditable={
                <ContentEditable
                  className={classNames(customContentClasses)}
                />
              }
              placeholder={placeholder}
              ErrorBoundary={LexicalErrorBoundary}
            />
          </>
        )}
        {(isCharLimit || isCharLimitUtf8) && (
          <CharacterLimitPlugin
            charset={isCharLimit ? 'UTF-16' : 'UTF-8'}
            maxLength={5}
          />
        )}
        {isAutocomplete && <AutocompletePlugin />}
      </div>
    </div>
  );
}

const Editor = ({
  existingContent,
  onChange,
  placeholderText,
  customContentClasses,
  imageIdentity,
  variables = [],
  isAutoFocus = true,
  editable = true,
  hideButtonPlugin = false,
  contentHTML = null,
  supportsYoutubeEmbed = false,
  customBorderClass = '',
  showToolBarOnHover = false,
  customFocusClass = ''
}: EditorProps) => {
  const previousContentRef = useRef(existingContent);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (existingContent) {
      setIsInitialized(true);
    }
    if (!previousContentRef.current && existingContent) {
      previousContentRef.current = existingContent;
    }
  }, [existingContent]);

  // When !isInitialized, render pure HTML version of content
  if (!editable && contentHTML && !isInitialized) {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
      </>
    );
  }

  //if the editor component is mounted on the server, we should stop the rendering
  if (typeof window === 'undefined') {
    return null;
  }

  if (!isInitialized) {
    return null;
  }

  const getInitialEditorState = (editor: LexicalEditor) => {
    try {
      if (existingContent && editor) {
        const sanitizedContent = sanitizeContentNodes({
          content: existingContent,
          variables,
          isEditable: editable
        });

        const editorState = editor.parseEditorState(sanitizedContent);
        editor.setEditorState(editorState);
        return editorState;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialConfig = {
    editorState: getInitialEditorState,
    namespace: 'primary-editor',
    editable: editable,
    nodes: [...NodeList],
    onError: (error: Error) => {
      console.error('Editor error:', error);
    },
    theme: PlaygroundEditorTheme,
    readOnly: !editable
  };

  const shouldShowToolBarOnHover = isSafari() ? false : showToolBarOnHover;

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorComponent
        onChange={onChange}
        placeholderText={placeholderText}
        customContentClasses={customContentClasses}
        imageIdentity={imageIdentity}
        hideButtonPlugin={hideButtonPlugin}
        variables={variables}
        isAutoFocus={isAutoFocus}
        supportsYoutubeEmbed={supportsYoutubeEmbed}
        customBorderClass={customBorderClass}
        showToolBarOnHover={shouldShowToolBarOnHover}
        customFocusClass={customFocusClass}
      />
    </LexicalComposer>
  );
};

export default Editor;
